<template>
<div></div>
</template>

<script>
export default {
    name: "TicketLogin",
    created() {
        this.ticketLogin()
    },
    methods:{
        ticketLogin() {
            const ticket = this.$route.query.t
            if (!ticket) {
                this.$message.error('参数错误')
                return
            }
            const params = {
                ticket
            }
            this.$httpUtil.post(this.$urlConstant.login.ticketLogin,params,res=>{
                if (process.env.VUE_APP_SITE_ID === res.data.siteId) {
                    this.$sess.login(res)
                    this.$httpUtil.post(this.$urlConstant.patient.currentDoctor,{},res=>{
                        const doctor = res.data
                        this.$sess.setCurrentDoctor(doctor)
                        this.$router.replace({name:'PatientList'})
                    },this)
                }else {
                    this.$message.error('没有权限')
                }
            },this)
        }
    }
}
</script>

<style scoped>

</style>